$earthi-vivid-green: #75b626;
$earthi-grass-green: #2d9147;
$earthi-sea-green: #426862;

$earthi-black: #000;
$earthi-space-grey: #565656;
$earthi-mid-grey: #9c9c9c;
$earthi-light-grey: #c5c6c6;
$earthi-white: #fff;

$earthi-deep-sea: #133042;
